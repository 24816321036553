import service_status from '@/@fake-db/data/service_status.json'
import queue from '@/api/queue/queue'
import userRole from '@/api/userRole'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import { ref, watch } from '@vue/composition-api'

export default function useServiceRoom() {
  const moment = require('moment')
  const serve_date = ref(moment(new Date()).format('YYYY-MM-DD'))
  const searchtext = ref('')
  const isShowDate1 = ref(false)
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const options = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      sortable: false,
      value: 'id',
      width: 30,
    },
    {
      text: `${i18n.t('a_number')} OPD`,
      value: 'opd',
      align: 'center',
      width: 110,
    },
    {
      text: `${i18n.t('service_number')}`,
      value: 'serve_id',
      align: 'center',
      width: 100,
    },

    {
      text: i18n.t('date'),
      value: 'serve_date',
      align: 'center',
      width: 160,
    },
    { text: i18n.t('customer'), value: 'customer_fullname', width: 250 },
    { text: i18n.t('doctor'), value: 'user_fullname', width: 170 },
    { text: i18n.t('service_room'), value: 'room_name', width: 145 },
    {
      text: i18n.t('service'),
      value: 'serve_status_id',
      align: 'center',
      width: 140,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      width: 190,
      align: 'center fixed',
    },
  ])
  const userList = ref([])
  const user_id = ref('')
  const statusData = ref(service_status.data)
  const serve_status_id = ref(service_status.data[0].value)
  const serve_comment = ref('')
  const dialogShowComment = ref(false)
  const isEditFee = ref(false)
  const isUserConfirm = ref(false)
  const userConfirmStatus = ref(1)
  const isCancel = ref(false)
  const dialogAddQ = ref(false)
  const dataForCheckq = ref({})
  const checkq_date = ref('')
  const serveId = ref('')

  const { userRoleList } = userRole

  const { queueServiceList } = queue

  const useService = item => {
    router.push({
      name: 'uservice-usage',
      query: {
        id: item.serve_id,
        cus_id: item.customer_id_pri,
        shop_id: item.shop_id_customer,
        date: item.serve_date,
      },
    })
  }

  const detail = id => {
    router
      .push({
        name: 'showDetailService',
        params: { id },
      })
      .catch(err => {
      })
  }

  const showCommemt = item => {
    serve_comment.value = item.serve_comment
    dialogShowComment.value = true
  }

  userRoleList({
    role_id: [1, 3],
  }).then(res => {
    userList.value = res
    userList.value.unshift({
      user_id: '',
      user_fullname: i18n.t('all'),
    })
  })

  const detailOpd = id => {
    router.push({
      name: 'ShowDetailCheck',
      params: { id },
    })
  }

  const fetchDataTable = event => {
    loading.value = true
    queueServiceList({
      serve_date: serve_date.value,
      serve_status_id: String(serve_status_id.value),
      user_id: user_id.value,
      searchtext: searchtext.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      dataTableList.value = data
      totalDataTableList.value = count
      segmentId.value = segment
      totalPage.value = count_of_page
      if (event == 'add') {
        options.value.page = 1
      }
      loading.value = false
    })
  }

  watch([searchtext, serve_date, serve_status_id, user_id, options], (newvalue, oldvalue) => {
    if (
      newvalue[0] !== oldvalue[0] ||
      newvalue[1] !== oldvalue[1] ||
      newvalue[2] !== oldvalue[2] ||
      newvalue[3] !== oldvalue[3]
    ) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  const serviceToCheckq = item => {
    checkq_date.value = moment(new Date()).format('YYYY-MM-DD')
    dialogAddQ.value = true
    dataForCheckq.value = item
  }

  const print = id => {
    const ids = [id]
    const routeData = router.resolve({
      name: 'printService',
      query: {
        id: String(ids),
      },
    })
    window.open(routeData.href, '_blank')
  }

  return {
    serveId,
    serve_date,
    searchtext,
    isShowDate1,
    dataTableList,
    totalDataTableList,
    segmentId,
    options,
    loading,
    footer,
    columns,
    fetchDataTable,
    dataForCheckq,
    totalPage,
    userList,
    statusData,
    serve_status_id,
    user_id,
    dialogShowComment,
    isCancel,
    useService,
    userConfirmStatus,
    dialogAddQ,
    detail,
    serve_comment,
    isEditFee,
    isUserConfirm,
    serviceToCheckq,
    showCommemt,
    detailOpd,
    print,
  }
}
