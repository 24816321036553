<template>
  <v-dialog
    v-model.trim='isShowAddCheckQ'
    max-width='500'
  >
    <v-card>
      <v-card-title>
        {{ $t('add_a_check_queue') }}
      </v-card-title>
      <v-card-text>
        <v-autocomplete
          v-model.trim='user_id'
          :label="$t('select_inspectors')"
          :items='userList'
          dense
          item-text='user_fullname'
          item-value='user_id'
          :auto-select-first='true'
          outlined
        ></v-autocomplete>
        <v-autocomplete
          v-model.trim='room_id'
          :items='roomList'
          dense
          item-text='room_name'
          item-value='room_id'
          :label="$t('select_examination_room')"
          :auto-select-first='true'
          outlined
        ></v-autocomplete>
        <v-dialog
          ref='datePicker'
          v-model.trim='isShowDate'
          :return-value.sync='checkq_date'
          width='290px'
        >
          <template v-slot:activator='{ on, attrs }'>
            <v-text-field
              v-model.trim='checkq_date'
              class='set-font'
              :label="$t('date')"
              v-bind='attrs'
              dense
              outlined
              v-on='on'
            ></v-text-field>
          </template>
          <v-date-picker
            v-model.trim='checkq_date'
            class='set-font'
            :locale='$i18n.locale'
            scrollable
          >
            <v-spacer></v-spacer>

            <v-btn
              text
              color='primary'
              @click='$refs.datePicker.save(checkq_date)'
            >
              {{ $t('confirm') }}
            </v-btn>
            <v-btn
              text
              color='secondary'
              @click='isShowDate = false'
            >
              {{ $t('cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
          </v-date-picker>
        </v-dialog>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          :loading='loading'
          :disabled='loading'
          @click='addCheckQ'
        >
          {{
            $t('confirm')
          }}
        </v-btn>
        <v-btn
          color='secondary'
          outlined
          @click="$emit('update:is-show-add-check-q',false)"
        >
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import shop from '@/api/shop'
import userRole from '@/api/userRole'
import examinationRoom from '@/api/systemSetting/examinationRoom'
import service from '@/api/service/service'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'

export default {
  model: {
    prop: 'isShowAddCheckQ',
    event: 'update:is-show-add-check-q',
  },
  props: {
    isShowAddCheckQ: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      default: '',
    },
    serviceId: {
      type: String,
      default: '',
    },

  },

  setup(props) {
    const moment = require('moment')
    const isShowDate = ref(false)
    const userList = ref([])
    const roomList = ref([])
    const room_id = ref('')
    const loading = ref(false)
    const user_id = ref('')
    const shop_id_pri = ref('')
    const checkq_date = ref(moment(new Date()).format('YYYY-MM-DD'))
    const { userRoleList } = userRole
    const { examinationRoomList } = examinationRoom
    const { shop: shopGet } = shop
    const { serviceAddQueueCheck } = service

    const getShop = () => {
      shopGet().then(res => {
        shop_id_pri.value = res.shop_id_pri
      })
    }

    const getUserList = () => {
      userRoleList({
        role_id: [1, 3],
      }).then(res => {
        userList.value = res
        user_id.value = localStorage.getItem('user_id')
      })
    }

    const getExaminationRoomList = () => {
      examinationRoomList({
        searchtext: '',
        room_status_id: 1,
      }).then(res => {
        roomList.value = res.data
        room_id.value = res.data[0].room_id
      })
    }

    const addCheckQ = () => {
      loading.value = true
      serviceAddQueueCheck({
        shop_id_pri: shop_id_pri.value,
        customer_id_pri: props.customerId,
        user_id: user_id.value,
        room_id: room_id.value,
        checkq_date: checkq_date.value,
        serve_id: props.serviceId,
        lang: i18n.locale,
      }).then(res => {
        if (res.response) {
          store.commit('app/ALERT', {
            message: res.message,
            color: 'success',
          })
          router.push({
            name: 'Checking',
            query: {
              id: res.data,
              serve_id: props.serviceId,
            },
          })
        } else {
          store.commit('app/ALERT', {
            message: res.message,
            color: 'error',
          })
          loading.value = false
        }
      })
    }

    watch(() => props.isShowAddCheckQ, value => {
      if (value) {
        getShop()
        getUserList()
        getExaminationRoomList()
      }
    })

    return {
      isShowDate,
      userList,
      user_id,
      loading,
      roomList,
      room_id,
      checkq_date,
      addCheckQ,
    }
  },

}
</script>

<style lang='scss' scoped>

</style>
