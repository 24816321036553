import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const queueList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'checkq/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get queue list err : ', err)

      return {}
    })

  return response
}

const queueAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checkq',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('add queue err : ', err)

      return {}
    })

  return response
}

const queueServiceList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.error('get queue service list err : ', err)

      return {}
    })

  return response
}
export default { queueList, queueAdd, queueServiceList }
