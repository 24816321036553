<template>
  <v-dialog
    v-model='isEditFee'
    max-width='700'
    persistent
  >
    <v-card>
      <v-card-title>
        {{ $t('hand_charge') }}
      </v-card-title>
      <v-simple-table dense>
        <thead>
        <tr>
          <th>
            #
          </th>
          <th>
            {{ $t('to_pay') }}
          </th>
          <th>
            {{ $t('doctor_staff_free') }}
          </th>
          <th>
            {{ $t('hand_charge') }}
          </th>
          <th>
            {{ $t('option') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for='(item, index) in costList.servinguser'
          :key='index'
        >
          <td>{{ index + 1 }}</td>
          <td>
            {{
              item.servinguser_type_id == '2' || item.servinguser_type_id == '3'
                ? `${$t('doctor_costs')}`
                : `${$t('staff_costs')}`
            }}
          </td>
          <td>{{ item.user_fullname }}</td>
          <td
            width='150px'
            colspan='2'
          >
            <v-text-field
              v-model='item.servinguser_cost'
              outlined
              class='my-2'
              dense
              :label="$t('doctor_staff_free')"
              hide-details
              reverse
              @blur='updateHandChange(item)'
              @keypress.enter='updateHandChange(item)'
            ></v-text-field>
          </td>
        </tr>
        <tr>
          <td
            colspan='3'
            class='font-weight-bold'
          >
            {{ $t('total') }} {{ $t('doctor_costs') }}
          </td>
          <td
            colspan='2'
            class='text-right'
          >
            <u class='font-weight-semibold primary--text'>
              {{ costList.sum_doctor || 0.00 }} {{ $t('bath') }}
            </u>
          </td>
        </tr>
        <tr>
          <td
            colspan='3'
            class='font-weight-bold'
          >
            {{ $t('total') }} {{ $t('staff_costs') }}
          </td>
          <td
            colspan='2'
            class='text-right'
          >
            <u class='primary--text font-weight-semibold'>
              {{ costList.sum_user || 0.00 }} {{ $t('bath') }}
            </u>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color='secondary'
          outlined
          @click="$emit('update:is-edit-fee',false)"
        >
          {{ $t('close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import service from '@/api/service/service'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default {
  model: {
    prop: 'isEditFee',
    event: 'update:is-edit-fee',
  },
  props: {
    isEditFee: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },

  setup(props) {
    const costList = ref({})

    const amountCourstList = ref([])

    const { serviceFeeUserList, serviceFeeUserUpdate } = service

    const getCost = () => {
      serviceFeeUserList(props.id).then(res => {
        costList.value = res
        res.servinguser.forEach(item => {
          amountCourstList.value.push(item.servinguser_cost)
        })
      })
    }

    const updateHandChange = item => {
      serviceFeeUserUpdate({
        serve_id: props.id,
        servinguser_id: item.servinguser_id,
        servinguser_cost: item.servinguser_cost,
        lang: i18n.locale,
      }).then(res => {
        if (res.response) {
          store.commit('app/ALERT', {
            message: 'update_data_successfully',
            color: 'success',
          })
        } else {
          store.commit('app/ALERT', {
            message: res.message,
            color: 'error',
          })
        }
        getCost()
      })
    }

    watch(() => props.isEditFee, value => {
      if (value) {
        getCost()
      }
    })

    return {
      costList,
      amountCourstList,
      updateHandChange,
    }
  },
}
</script>
