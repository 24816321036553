import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'
import { api as api2 } from '../mainNode'

const serviceList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'service/list',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get service list err : ', err)

      return {}
    })

  return response
}

const serviceDetailList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'service/viewlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get detail service list err : ', err)

      return []
    })

  return response
}

const serviceCustomerList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checkq/customer',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('customer for use service list err : ', err)

      return []
    })

  return response
}

const serviceCourseList = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'serve/courselist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get course list err : ', err)

      return []
    })

  return response
}

const serviceServeList = async body => {
  ////await refreshToken()
  const response = await api2
    .post({
      path: 'serve/servinglist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get serving list err : ', err)

      return []
    })

  return response
}

const serviceDoctorList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/doctorlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get doctor of course list err : ', err)

      return []
    })

  return response
}

const serviceCourseDrugList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/coursedruglist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get course drug list err : ', err)

      return []
    })

  return response
}
const serviceDrugAll = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/drugall',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get course all drug list err : ', err)

      return {}
    })

  return response
}
const serviceUserList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/userlist',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('get user list err : ', err)

      return []
    })

  return response
}
const serviceSumServe = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'serve/sumserve',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('sum serve : ', err)

      return {}
    })

  return response
}

const serviceServe = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'serve',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('serve error : ', err)

      return {}
    })

  return response
}
const serviceServiceCourse = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'serve/servingcost',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('service course error : ', err)

      return {}
    })

  return response
}

const serviceCancel = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'serve/servingserve',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('cancel serve error : ', err)

      return {}
    })

  return response
}

const serviceUserDoctor = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'serve/userdoctor',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('userdoctor serve error : ', err)

      return {}
    })

  return response
}
const serviceUserDoctorDelete = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'serve/userdoctor',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('delete userdoctor serve error : ', err)

      return {}
    })

  return response
}

const serviceAddStaff = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/user',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add staff serve error : ', err)

      return {}
    })

  return response
}

const serviceAddDoctor = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/doctor',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add doctor serve error : ', err)

      return {}
    })

  return response
}

const serviceDeleteCourse = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'serve/serving',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('delete doctor serve error : ', err)

      return {}
    })

  return response
}

const serviceCheckDrug = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'serve/checkdrug',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('check serve error : ', err)

      return {}
    })

  return response
}

const serviceAddCartCourse = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/serving',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add course to cart serve error : ', err)

      return {}
    })

  return response
}
const serviceDeleteDrugCourse = async id => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'serve/coursedrug',
      param: id,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('delete drug from course error : ', err)

      return {}
    })

  return response
}

const serviceUpdateDrugAmount = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'serve/coursedrug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('delete drug from course error : ', err)

      return {}
    })

  return response
}
const serviceAddDrug = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/coursedrug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add drug to course error : ', err)

      return {}
    })

  return response
}

const serviceSelectDrug = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/drugselect',
      body,
    })
    .then(res => (res.data || []))
    .catch(err => {
      console.error('select drug to course error : ', err)

      return []
    })

  return response
}

const serviceDrugGet = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'serve/drug',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('get drug by id error : ', err)

      return {}
    })

  return response
}

const serviceFeeUserList = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'serve/servinguserlist',
      param: id,
    })
    .then(res => (res.data || {}))
    .catch(err => {
      console.error('get fee user list : ', err)

      return {}
    })

  return response
}
const serviceFeeUserUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'serve/servinguser',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('update fee user list : ', err)

      return {}
    })

  return response
}

const serviceAddQueueCheck = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'checkq/checkserve',
      body,
    })
    .then(res => (res || {}))
    .catch(err => {
      console.error('add queue check from service: ', err)

      return {}
    })

  return response
}
export default {
  serviceList,
  serviceDetailList,
  serviceCustomerList,
  serviceCourseList,
  serviceServeList,
  serviceDoctorList,
  serviceCourseDrugList,
  serviceDrugAll,
  serviceUserList,
  serviceSumServe,
  serviceServe,
  serviceServiceCourse,
  serviceCancel,
  serviceUserDoctor,
  serviceUserDoctorDelete,
  serviceAddStaff,
  serviceAddDoctor,
  serviceDeleteCourse,
  serviceCheckDrug,
  serviceAddCartCourse,
  serviceDeleteDrugCourse,
  serviceUpdateDrugAmount,
  serviceAddDrug,
  serviceSelectDrug,
  serviceDrugGet,
  serviceFeeUserList,
  serviceFeeUserUpdate,
  serviceAddQueueCheck,
}
