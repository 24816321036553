<template>
  <v-dialog
    v-model="isCancel"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        {{ $t('confirm_cancellation') }}
      </v-card-title>
      <v-form
        ref="formCancel"
        @submit.prevent="serviceCancel"
      >
        <v-card-text>
          <v-textarea
            v-model="serve_comment"
            :label="$t('note_of_cancellation')"
            :rules="[required]"
            outlined
            dense
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="loading"
            type="submit"
          >
            {{ $t("confirm") }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-cancel',false)"
          >
            {{ $t("cancel") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import serviceDetail from '@/api/service/serviceDetail'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default {
  model: {
    prop: 'isCancel',
    event: 'update:is-cancel',
  },
  props: {
    isCancel: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const serve_comment = ref('')
    const formCancel = ref(null)
    const loading = ref(false)

    const { serviceDetailStatusUpdate } = serviceDetail
    const serviceCancel = () => {
      const isFormValid = formCancel.value.validate()
      if (!isFormValid) return
      loading.value = true
      serviceDetailStatusUpdate({
        serve_id: props.id,
        serve_comment: serve_comment.value,
        lang: i18n.locale,
      }).then(res => {
        store.commit('app/setStatusUpdateHeader')

        if (res.response) {
          store.commit('app/ALERT', {
            message: 'delete_data_successfully',
            color: 'success',
          })
        } else {
          store.commit('app/ALERT', {
            message: res.message,
            color: 'error',
          })
        }
        emit('onUpdate')
        emit('update:is-cancel', false)
        loading.value = false
      })
    }

    watch(() => props.isCancel, value => {
      if (value) {
        serve_comment.value = ''
      }
    })

    return {
      serve_comment,
      formCancel,
      loading,
      serviceCancel,
      required,
    }
  },

}
</script>
