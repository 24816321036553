import refreshToken from '@/plugins/refresh-token'
import { api } from '../main'

const examinationRoomList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'room/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get examination room list err : ', err)

      return {}
    })

  return response
}

const addNewRoom = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'room',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add new room err : ', err)

      return {}
    })

  return response
}

const roomUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'room',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add new room err : ', err)

      return {}
    })

  return response
}

const roomStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'room/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update status room err : ', err)

      return {}
    })

  return response
}

export default {
  examinationRoomList,
  addNewRoom,
  roomUpdate,
  roomStatusUpdate,
}
